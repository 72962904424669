import {CartItem, ProductItem} from "../../models/product-item";
import Discount from "../../models/discount";
import Address from "../../models/address";
import CreditCard from "../../models/credit-card"

// Action Types
export const ADD_ITEM = 'ADD_ITEM';
export const DELETE_ITEM = 'DELETE_ITEM';
export const SET_CART = 'SET_CART';
export const ADD_DISCOUNT = 'ADD_DISCOUNT';
export const REMOVE_DISCOUNT = 'REMOVE_DISCOUNT';
export const ADD_MEMBER_POINTS = 'ADD_MEMBER_POINTS';
export const REMOVE_MEMBER_POINTS = 'REMOVE_MEMBER_POINTS';
export const UPDATE_SHIPPING = 'UPDATE_SHIPPING';
export const UPDATE_PAYMENT = 'UPDATE_PAYMENT';
export const UPDATE_DELIVERY = 'UPDATE_DELIVERY';

interface AddItem {
    type: typeof ADD_ITEM,
    payload: {
        product: ProductItem,
        variation: string
    }
}

interface RemoveItem {
    type: typeof DELETE_ITEM,
    payload: {
        product: ProductItem,
        variation: string,
        clear?: boolean
    }
}

interface SetCart {
    type: typeof SET_CART,
    payload: {
        cart: CartItem[],
        uid: string | null,
        discount?: Discount
        memberPoints?: number
        address?: Address
        method?: string
        methodInstrument?: string
        card?: CreditCard
        delivery_type?: string
    }
}

interface AddDiscount {
    type: typeof ADD_DISCOUNT,
    payload: {
        discount: Discount
    }
}

interface RemoveDiscount {
    type: typeof REMOVE_DISCOUNT,
    payload: {
        discount: Discount
    }
}

interface AddMemberPoints {
    type: typeof ADD_MEMBER_POINTS,
    payload: {
        memberPoints: number
    }
}

interface RemoveMemberPoints {
    type: typeof REMOVE_MEMBER_POINTS,
    payload: {
        memberPoints: number
    }
}

interface UpdateShipping {
    type: typeof UPDATE_SHIPPING,
    payload: {
        address: Address
    }
}

interface UpdatePayment {
    type: typeof UPDATE_PAYMENT,
    payload: {
        method: string
        methodInstrument: string
        card: CreditCard
    }
}

interface UpdateDelivery {
    type: typeof UPDATE_DELIVERY,
    payload: {
        delivery_type: string
    }
}

export type CartActionTypes = AddItem | RemoveItem | SetCart | AddDiscount | RemoveDiscount | AddMemberPoints | RemoveMemberPoints | UpdatePayment | UpdateShipping | UpdateDelivery

// State
export class CartState {
    uid?: string | null = null
    loading?: boolean = true
    status?: boolean = false;

    items: CartItem[] = [];
    totalItems: number = 0

    discount?: Discount = undefined

    orderValue: number = 0
    orderValueWithTaxes: number = 0

    promotionalDiscount: number = 0
    retailDiscount: number = 0
    memberDiscount: number = 0

    totalDiscount: number = 0

    address: null | Address = null;

    method: string = "";
    methodInstrument: string = "";
    card: CreditCard | null = null;

    delivery_type: string = "";

    constructor(data?: {[field: string]: any}) {
        this.uid = data?.uid ?? null
        this.loading = data?.loading ?? true
        this.status = data?.status ?? false;
        this.discount = data?.discount ? new Discount(data?.discount) : null
        this.items = data?.items ?? []
        this.totalItems = parseFloat(data?.totalItems ?? 0) ?? 0

        this.orderValue = parseFloat(data?.orderValue ?? 0) ?? 0
        this.orderValueWithTaxes = parseFloat(data?.orderValueWithTaxes ?? 0) ?? 0

        this.promotionalDiscount = parseFloat(data?.promotionalDiscount ?? 0) ?? 0
        this.retailDiscount = parseFloat(data?.retailDiscount ?? 0) ?? 0
        this.memberDiscount = parseFloat(data?.memberDiscount ?? 0) ?? 0

        this.totalDiscount = parseFloat(data?.totalDiscount) ?? 0

        this.address = new Address(data?.address);

        this.method = data?.method;
        this.methodInstrument = data?.methodInstrument;
        this.card = new CreditCard(data?.card);

        this.delivery_type = data?.delivery_type;
    }

    get shipping(): number {
        if (this.orderValueWithTaxes > 595 || this.orderValueWithTaxes === 0)
            return 0;
        return 95;
    }

    get hasDiscountOnly(): boolean {
        let hasDiscountOnly = true;
        this.items.forEach((i) => {
            if (i.totalDiscount === 0)
                hasDiscountOnly = false;
        })
        return hasDiscountOnly;
    }

    get taxes(): number {
        return (this.orderTotal/1.18)*0.18
    }

    get orderTotal(): number {

        let total = this.orderValue - this.totalDiscount;

        if (total > 595)
            return total;
        else {
            if (total === 0)
                return 0;
            return total + 95;
        }
    }

    get step(): string {
        if (this.uid) {
            if (!this.address.exists) {
                return "/";
            } else if (this.address.exists && !this.delivery_type) {
                return "/delivery"
            } else if (this.address.exists && this.delivery_type && !this.method) {
                return "/payments"
            } else if (this.address.exists && this.delivery_type && this.method) {
                return "/review"
            }
        } else {
            if (!this.address.email) {
                return "/";
            } else if (!this.address.exists) {
                return "/";
            } else if (this.address.exists && this.address.email && !this.delivery_type) {
                return "/delivery"
            } else if (this.address.exists && this.address.email && this.delivery_type && !this.method) {
                return "/payments"
            } else if (this.address.exists && this.address.email && this.delivery_type && this.method) {
                return "/review"
            }
        }
        return "/";
    }
}